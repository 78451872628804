export default function LiveData({ messages, isOnline }) {
  const formatTimestamp = (timestamp) =>
    timestamp ? new Date(timestamp).toLocaleTimeString() : "N/A"

  const formatValue = (value, decimals = 1) =>
    value !== undefined ? value?.toFixed(decimals) : "N/A"

  function formatStatusData(data) {
    return Object.entries(data).map(([sensor_id, d]) => (
      <p key={d.id}>
        Signal strength: {formatValue(d.signal_percentage, 0)}{"%"}
        {d.signal_operator && ` (${d.signal_operator})`}
      </p>
    ))
  }

  function formatRotationData(data) {
    return Object.entries(data).map(([sensor_id, d]) => (
      <p key={d.id}>
        {formatValue(d.value)} rotations,{" "}
        {formatValue(d.rpm)} RPM,{" "}
        {formatTimestamp(d.timestamp)}
      </p>
    ))
  }

  function formatInaData(data) {
    return Object.entries(data).map(([sensor_id, d]) => (
      <p key={d.id}>
        {d.sensor_id}: {formatValue(d.bus_voltage)} V,{" "}
        {formatValue(d.bus_current)} A,{" "}
        {formatValue(d.power)} W,{" "}
        {formatTimestamp(d.timestamp)}
      </p>
    ))
  }

  function formatLoadcellData(data) {
    return Object.entries(data).map(([sensor_id, d]) => {
      // Apply calibration
      if (!d.weight && d.raw) {
        if (sensor_id === "loadcell_wec" || d.sensor_id === "loadcell_wec")
          d["weight"] = (d.raw - 20798) / 2183
        else if (sensor_id === "loadcell_buoy" || d.sensor_id === "loadcell_buoy")
          d["weight"] = (d.raw - 32658) / 1953
      }

      return (
        <p>
          {sensor_id || d.sensor_id}: {formatValue(d.weight, 0)} kg,{" "}
          {formatTimestamp(d.timestamp)}
        </p>
      )
    })
  }

  return (
    <div>
      {isOnline && messages?.status && formatStatusData(messages.status)}
      {messages?.rotation && formatRotationData(messages.rotation)}
      {messages?.ina && formatInaData(messages.ina)}
      {messages?.loadcell && formatLoadcellData(messages.loadcell)}
    </div>
  )
}