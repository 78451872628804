import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  width: 97%;
  height: 1700px;
`

export default function Data() {
  return (
    <Container>
      <iframe src={process.env.DASH_APP_URL || "https://dash.wecoenergy.com"} title='Dash' style={{ flexGrow: 1, border: 'none' }} />
    </Container>
  )
}
