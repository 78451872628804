import { useEffect, useState } from 'react';
import styled from 'styled-components'
import { GoogleLogin } from '@react-oauth/google';
import { socket } from './socket';
import { getWecs } from './server';
import { authenticate, login, logout } from './server';
import Home from './Home';

const LoginContainer = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Logo = styled.img`
  position: absolute;
  top: -1vh;
  left: -1vw;
  max-height: 30vh;
  max-width: 30vw;
`

const User = styled.div`
  position: absolute;
  top: 1vh;
  right: 1vw;
  max-height: 30vh;
  max-width: 30vw;
  text-align: end;
  line-height: 1.4em;
`

const LogoutButton = styled.div`
  cursor: pointer;
`

const Title = styled.h1`
  margin: 0
`

export default function App() {
  const [loggedIn, setLoggedIn] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)
  const [connected, setConnected] = useState(null)
  const [user, setUser] = useState(null)
  const [wecs, setWecs] = useState(null)

  useEffect(() => {
    (async () => {
      try {
        const data = await login()
        setUser(data.name)
        setLoggedIn(true)
      }
      catch (e) {
        console.log('Login failed', e)
      }
    })()
  }, [])

  useEffect(() => {
    if (loggedIn) {
      (async function () {
        socket.connect()
        try {
          const wecs = await getWecs()
          wecs.sort((a, b) => -a.last_online?.localeCompare(b.last_online))
          console.log('Got wecs:', wecs)
          setWecs(wecs)
        } catch (e) {
          console.error("Failed to get Wecs", e)
        }
      })()
    }
    else {
      socket.disconnect()
      setWecs(null)
    }
  }, [loggedIn])

  useEffect(() => {
    function onConnect() {
      console.log('Socket connected')
      setConnected(true)
    }

    function onDisconnect() {
      console.log('Socket disconnected')
      setConnected(false)
    }

    function onReconnect() {
      console.log('Socket reconnecting')
      setConnected(null)
    }

    function onWec(data) {
      console.log('onWec', data)
      updateWec(data)
    }

    function onWecs(data) {
      console.log('onWecs', data)
      setWecs(data)
    }

    socket.on('connect', onConnect)
    socket.on('disconnect', onDisconnect)
    socket.on('reconnect', onReconnect)
    socket.on('wec', onWec)
    socket.on('wecs', onWecs)

    return () => {
      socket.off('connect', onConnect)
      socket.off('disconnect', onDisconnect)
      socket.off('reconnect', onReconnect)
      socket.off('wec', onWec)
      socket.off('wecs', onWecs)
    }
  }, [])

  function updateWec(updatedWec) {
    setWecs(wecs => wecs?.map(wec => wec.id === updatedWec.id ? { ...wec, ...updatedWec } : wec))
  }

  async function onGoogleLoginSuccess(response) {
    try {
      const data = await authenticate(response.credential)
      console.log('Authentication with Google token successful', data)
      setLoggedIn(true)
      setUser(data.name)
      setErrorMessage(null)
    }
    catch (e) {
      console.log('Authentication with Google token failed', e)
      setErrorMessage('Authentication failed')
    }
  }

  function onGoogleLoginError(error) {
    console.log("Google login error", error)
    setErrorMessage('Google login error')
  }

  async function onLogoutClicked() {
    await logout()
    setLoggedIn(false)
  }

  if (!loggedIn)
    return (
      <LoginContainer>
        <Logo src={'weco-logo-white.svg'} alt='logo' />
        <Title>Welcome</Title>
        <br /><br />
        <GoogleLogin onSuccess={onGoogleLoginSuccess} onError={onGoogleLoginError} auto_select />
        {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      </LoginContainer>
    )

  return (
    <div>
      <Logo src={'weco-logo-white.svg'} alt='logo' />
      <User>
        <div>{user}</div>
        <LogoutButton onClick={onLogoutClicked}>Logout</LogoutButton>
      </User>
      <Home wecs={wecs} updateWec={updateWec} />
    </div>
  )
}
