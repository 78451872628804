export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'http://localhost:5000' // 'https://api.wecoenergy.com' //

/**
 * Authenticates the user with the given token and returns the response data.
 * @param {string} token The token to authenticate with.
 * @returns {Promise<object>} The response data.
 */
export async function authenticate(token) {
  console.log('authenticate')
  const response = await fetch(SERVER_URL + '/authenticate', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ token }),
    credentials: 'include'
  })

  if (!response.ok)
    throw new Error(response.statusText)

  return await response.json()
}

/**
 * Logs in to the server and returns the response data.
 * @returns {Promise<object>} The response data.
 */
export async function login() {
  console.log('Login')
  const response = await fetch(SERVER_URL + '/login', { credentials: 'include' })

  if (!response.ok)
    throw new Error(response.statusText)

  console.log('Login success')
  return await response.json()
}

/** 
 * Logs out of the server and return if successful
 */
export async function logout() {
  console.log('logout')
  const response = await fetch(SERVER_URL + '/logout', { credentials: 'include' })

  if (!response.ok)
    throw new Error(response.statusText)

  console.log('Logout success')
}

export async function getWecs() {
  const response = await fetch(
    SERVER_URL + '/wecs',
    { credentials: 'include' }
  )

  if (!response.ok)
    throw new Error(response.statusText)

  return await response.json()
}

export async function patchWec(id, data) {
  const response = await fetch(
    SERVER_URL + '/wecs/' + id,
    {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data }),
      credentials: 'include'
    }
  )

  if (!response.ok)
    throw new Error(response.statusText)

  return await response.json()
}

export async function turnOffWec(id) {
  const response = await fetch(
    SERVER_URL + '/turn_off/' + id,
    {
      method: 'POST',
      credentials: 'include'
    }
  )

  if (!response.ok)
    throw new Error(response.statusText)

  return await response.text()
}

/**
 * Gets data for a certain period
 * @param {String} type The data type
 * @param {String} sensor_id id of the sensor
 * @param {Date} startDateTime The start datetime
 * @param {Date} endDateTime The end datetime
 * @returns {Promise<object>} The rotation data
 */
export async function getData(type, sensor_id, startDateTime, endDateTime) {
  console.log(`Getting ${type} data from ${startDateTime} to ${endDateTime}`)
  const params = new URLSearchParams({ type, sensor_id, from: startDateTime.toISOString(), to: endDateTime.toISOString() })
  const response = await fetch(
    SERVER_URL + '/get_data?' + params,
    { credentials: 'include' }
  )

  if (!response.ok)
    throw new Error(response.statusText)

  return await response.json()
}

export async function getPictureList() {
  const response = await fetch(
    SERVER_URL + '/get_picture_list',
    { credentials: 'include' }
  )

  if (!response.ok)
    throw new Error(response.statusText)

  return await response.json()
}

export async function getSignedUrl(filename) {
  const response = await fetch(
    SERVER_URL + '/get_signed_url/' + filename,
    { credentials: 'include' }
  )

  if (!response.ok)
    throw new Error(response.statusText)

  return await response.text()
}