import { useState, useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { turnOffWec } from '../../server'
import LiveData from '../Items/LiveData';

const SettingsButton = styled.div`
  cursor: pointer;
  width: fit-content;
  justify-self: center;
  user-select: none;
  padding: 1em;
`

const SettingsContainer = styled.div`
  display:none;
  pointer-events: none;
  ${(props) => props.$enabled && `
    display: block;
    pointer-events: auto;
  `}
`

const Button = styled.button`
  padding: 1em;
  margin: 1em;
  border-radius: 10px;
  background-color: rgb(0 79 255);
  border: none;
  color: white;
  font-family: inherit;
  cursor: pointer
  ${(props) => props.disabled && `
    background-color: transparent;
    cursor: default;
  `}
`

const Title = styled.h1`
  margin: 0
`

export default function WECs({ connected, wec, messages }) {
  const [checkboxDisabled, setCheckboxDisabled] = useState(false)
  const [showSettings, setShowSettings] = useState(false)
  const name = wec.name || wec.hostname || wec.id
  const [isOnline, setIsOnline] = useState(false)

  useEffect(() => {
    console.log(`${wec.hostname} messages:`, messages)
    setIsOnline(messages.status?.status?.connected === true)
  }, [wec.hostname, messages])

  const title = useMemo(() => {
    if (connected === true) {
      return isOnline ? `${name} is online` : `${name} is offline`
    }
    else if (connected === false)
      return 'No connection'
    else
      return 'Connecting...'
  }, [connected, isOnline, name])

  async function onTurnOffWec() {
    setCheckboxDisabled(true)
    if (window.confirm(`Are you sure you want to turn off ${name}?`)) {
      try {
        console.log(`Turning off wec ${wec.id}`)
        await turnOffWec(wec.id)
      }
      catch (error) {
        console.error("Error patching wec", error)
      }
    }
    setCheckboxDisabled(false)
  }

  if (!wec)
    return <div />

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Title>{title}</Title>
        <LiveData messages={messages} isOnline={isOnline} />
      </div>
      {isOnline && <div>
        <SettingsButton onClick={() => setShowSettings(value => !value)}>Settings {showSettings ? "▼" : "◀"}</SettingsButton>
        <SettingsContainer $enabled={showSettings}>
          <Button
            disabled={checkboxDisabled}
            onClick={onTurnOffWec}
          >Turn off</Button>
        </SettingsContainer>
      </div>}
    </div>
  )
}
