// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("fonts/Gilroy-Light.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("fonts/Gilroy-ExtraBold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: Gilroy;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

@font-face {
  font-family: Gilroy;
  font-weight: bold;
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-family: Gilroy, helvetica, arial, sans-serif;
  background-color: #ebfaff;
  color: #0d1941;
  overflow: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,4CAAkC;AACpC;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,4CAAsC;AACxC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,iDAAiD;EACjD,yBAAyB;EACzB,cAAc;EACd,cAAc;EACd,mCAAmC;EACnC,kCAAkC;AACpC","sourcesContent":["@font-face {\n  font-family: Gilroy;\n  src: url('fonts/Gilroy-Light.otf');\n}\n\n@font-face {\n  font-family: Gilroy;\n  font-weight: bold;\n  src: url('fonts/Gilroy-ExtraBold.otf');\n}\n\nbody {\n  width: 100%;\n  height: 100%;\n  margin: 0;\n  font-family: Gilroy, helvetica, arial, sans-serif;\n  background-color: #ebfaff;\n  color: #0d1941;\n  overflow: auto;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
