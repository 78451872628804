import { useEffect, useState } from 'react'
import mqtt from "mqtt"

/**
 * Custom hook to connect to MQTT broker
 * @returns {object} client - MQTT client
 */
export function useMQTTConnect(onMessage) {
  const [client, setClient] = useState(null)
  
  useEffect(() => {
    const client = mqtt.connect("wss://emqx.wecoenergy.com:8084/mqtt", {
      username: "test",
      password: "test",
    })
    setClient(client)
  }, [])

  useEffect(() => {
    if (client) {
      client.on('connect', () => {
        console.log("MQTT connected")
        client.subscribe('weco/#', (err) => {
          if (err) {
            console.error('Failed to subscribe to weco/#', err)
          }
        })
      })

      client.on('message', function (topic, message) {
        onMessage(topic, JSON.parse(message))
      })

      client.on('disconnect', () => {
        console.log("MQTT disconnected")
      })

      client.on('error', (err) => {
        console.error('MQTT connection error:', err)
        client.end()
      })
      
      client.on('reconnect', () => {
        console.log("MQTT reconnecting")

      })
    }
  }, [client, onMessage])

  return client
}

/**
 * MQTT subscribe and publish functions
 * @param {mqtt.Client} client - MQTT client
 * @param {string} topic - topic to subscribe to
 * @param {function} onMessage - callback function to handle incoming messages
 */
export function useMQTTSubscribe(client, topic, onMessage) {
  useEffect(() => {
    if (!client || !client.connected) return

    const handleMsg = function (receivedTopic, message) {
      if (receivedTopic === topic) {
        onMessage(message.toString())
      }
    }

    console.log(`Subscribing to ${topic}`)
    client.subscribe(topic)
    client.on('message', handleMsg)
    
    return () => {
      console.log(`Unsubscribing from ${topic}`)
      client.unsubscribe(topic)
      client.off('message', handleMsg)
    }
  }, [client, topic, onMessage])
}

/**
 * MQTT publish function
 * @param {mqtt.Client} client - MQTT client
 * @returns {function} publish - function to publish messages
 */
export function useMQTTPublish(client) {
  return function (topic, message, options = {}) {
    if (client && client.connected) {
      client.publish(topic, message, options)
    }
  }
}