import { useState } from 'react';
import styled from 'styled-components'
import Camera from './components/Pages/Camera'
import Rotations from './components/Pages/Rotations';
import Power from './components/Pages/Power';
import Settings from './components/Pages/Settings';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  gap: 3vmax;
  padding: 17vh 0;
  max-width: 1200px;
  margin: 0 auto;
`

const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const Tab = styled.button`
  display: inline-block;
  background-color: transparent;
  border: none;
  color: white;
  width: 8em;
  height: 3em;
  border-radius: 99px;
  font-size: 1em;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  ${(props) => props.selected && `
    background-color: rgb(0 79 255);
    cursor: default;
  `}
`

export default function Home({connected, wecs, updateWec}) {
  const [activeTab, setActiveTab] = useState('Settings')
  
  return (
    <Container>
      <Tabs>
        <Tab selected={activeTab === 'Settings'} onClick={() => setActiveTab('Settings')}>WECs</Tab>
        <Tab selected={activeTab === 'Pictures'} onClick={() => setActiveTab('Pictures')}>Pictures</Tab>
        <Tab selected={activeTab === 'Rotations'} onClick={() => setActiveTab('Rotations')}>Rotations</Tab>
        <Tab selected={activeTab === 'Power'} onClick={() => setActiveTab('Power')}>Power</Tab>
      </Tabs>
      {activeTab === 'Pictures' && <Camera wecs={wecs} />}
      {activeTab === 'Rotations' && <Rotations />}
      {activeTab === 'Power' && <Power />}
      {activeTab === 'Settings' && wecs?.map(wec => <Settings key={wec.id} connected={connected} wec={wec} updateWec={updateWec} />)}
    </Container>
  )
}