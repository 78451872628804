import { useEffect, useState } from 'react';
import { socket } from '../../socket'

export default function LiveData({ wec }) {
  const [liveData, setLiveData] = useState({})

  useEffect(() => {
    function onSensorData({ wec_id, type, data }) {
      console.log('onSensorData', wec_id, type, data?.[0])
      if (wec_id === wec.id) {
        setLiveData(oldData => ({
          ...oldData,
          [type]: [
            ...(oldData[type] ? oldData[type]?.filter(d => d.sensor_id !== data?.[0]?.sensor_id) : []),
            data?.[0]
          ].sort((a, b) => a.sensor_id.localeCompare(b.sensor_id))
        }))
      }
    }

    socket.on('sensor_data', onSensorData)
    return () => {
      socket.off('sensor_data', onSensorData)
    }
  })

  return (
    <div>
      {!wec?.['online'] && <p>Last online: {new Date(wec?.['last_online']).toLocaleString()}</p>}
      {liveData?.['power']?.map(power => <p>Battery: {power['battery_percentage']}%<br />{power['power_input_status']}, {new Date(power['timestamp']).toLocaleTimeString()}</p>)}
      {liveData?.['rotation']?.map(rotation => <p>{rotation['value']?.toFixed(1)} rotations, {new Date(rotation['timestamp']).toLocaleTimeString()}</p>)}
      {liveData?.['ina']?.map(ina => <p>{ina['sensor_id']}: {ina['bus_voltage']?.toFixed(1)} V, {ina['bus_current']?.toFixed(1)} A, {ina['power']?.toFixed(1)} W, {new Date(ina['timestamp']).toLocaleTimeString()}</p>)}
      {liveData?.['loadcell']?.map(loadcell => <p>{loadcell['sensor_id']}: {loadcell['weight']?.toFixed(0)} kg, {new Date(loadcell['timestamp']).toLocaleTimeString()}</p>)}
      {liveData?.['gps']?.map(gps => <p>GPS: {gps['latitude']}, {gps['longitude']}, {new Date(gps['timestamp']).toLocaleTimeString()}</p>)}
    </div>
  )
}