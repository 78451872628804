import { useState } from "react";
import styled from 'styled-components'
import { Button } from "../Items/Button";
import { getData } from "../../server";
import LineChart from "../Items/LineChart";
import { CSVLink } from "react-csv";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 16px;
  justify-content: center;
`

const timePattern = /^(2[0-3]|[01]?\d)?:?([0-5]?\d)?$/

const DateInput = styled('input')`
  width: 8em;
  padding: 1em;
`

const TimeInput = styled.input`
  width: 3em;
  padding: 1em;
  font-family: monospace;
  text-align: center;
`

const CSVLinkButton = styled(CSVLink)`
  color: white;
  text-decoration: none;
`

function calculateRotationalSpeeds(rotations) {
  const rotationalSpeeds = []
  for (let i = 1; i < rotations.length; i++) {
    const rotationDiff = rotations[i].value - rotations[i - 1].value
    const timeDiff = new Date(rotations[i].timestamp) - new Date(rotations[i - 1].timestamp)

    const rotationalSpeedRPM = 60 * 1000 * rotationDiff / timeDiff

    rotationalSpeeds.push({ timestamp: rotations[i].timestamp, value: rotationalSpeedRPM })
  }
  return rotationalSpeeds
}

export default function Rotations() {
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().substring(0, 10))
  const [startTime, setStartTime] = useState('00:00')
  const [endTime, setEndTime] = useState('23:59')
  const [isLoading, setIsLoading] = useState(false)
  const [rawData, setRawData] = useState(null)
  const [rotations, setRotations] = useState(null)
  const [rotationalSpeeds, setRotationalSpeeds] = useState(null)

  function onDateChanged(e) {
    setRawData(null)
    setSelectedDate(e.target.value)
  }

  function onStartTimeChanged(e) {
    const startTime = e.target.value
    if (timePattern.test(startTime)) {
      setRawData(null)
      setStartTime(startTime)
    }
  }

  function onEndTimeChanged(e) {
    const endTime = e.target.value;
    if (timePattern.test(endTime)) {
      setRawData(null)
      setEndTime(endTime)
    }
  }

  async function onGenerateClicked() {
    setIsLoading(true)
    setRawData(null)
    try {
      const startDateTime = new Date(`${selectedDate} ${startTime}`)
      const endDateTime = new Date(`${selectedDate} ${endTime}`)
      const rotations = await getData("rotation", null, startDateTime, endDateTime)
      console.log("rotations:", rotations)
      setRawData(rotations)

      setRotations(rotations.map(data => ({ x: new Date(data.timestamp), y: data.value })))

      const rotationalSpeeds = calculateRotationalSpeeds(rotations)
      console.log("rotationalSpeeds:", rotationalSpeeds)
      setRotationalSpeeds(rotationalSpeeds.map(data => ({ x: new Date(data.timestamp), y: data.value })))
    }
    catch (e) {
      console.log('Couldn\'t get rotations', e)
    }

    setIsLoading(false)
  }

  return (
    <div style={{ width: '100%' }}>
      <Container>
        <DateInput type='date' value={selectedDate} onChange={onDateChanged} />
        <TimeInput value={startTime} onChange={onStartTimeChanged} />
        <TimeInput value={endTime} onChange={onEndTimeChanged} />
        <Button onClick={() => onGenerateClicked()} disabled={isLoading}>Generate</Button>
        {rawData && <Button><CSVLinkButton data={rawData} filename={`rotations-${selectedDate}-${startTime}-${endTime}.csv`}>Export</CSVLinkButton></Button>}
      </Container>
      <br /><br />
      {rotations && <LineChart type={'line'} data={rotations} label={'Rotations'} xlabel={'Time'} ylabel={'Rotation'} />}
      <br /><br />
      {rotationalSpeeds && <LineChart type={'scatter'} data={rotationalSpeeds} label={'Rotational speed'} xlabel={'Time'} ylabel={'Rotational speed (RPM)'} />}
    </div>
  )
}