import { useCallback, useState } from 'react';
import styled from 'styled-components'
import Camera from './components/Pages/Camera'
import Data from './components/Pages/Data';
import WECs from './components/Pages/WECs';
import { useMQTTConnect } from './hooks/mqtt';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  gap: 3vmax;
  padding: 17vh 0;
  max-width: 1200px;
  margin: 0 auto;
`

const Tabs = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 8px;
`

const Tab = styled.button`
  display: inline-block;
  background-color: transparent;
  border: none;
  color: #529fce;
  width: 8em;
  height: 3em;
  border-radius: 99px;
  font-size: 1em;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  ${(props) => props.selected && `
    background-color: #529fce;
    cursor: default;
    color: white;
  `}
`

export default function Home({wecs, updateWec}) {
  const [activeTab, setActiveTab] = useState('WECs')
  const [messages, setMessages] = useState({})

  const onMessage = useCallback((topic, data) => {
    // console.log(`Received message on ${topic}:`, data)
    const [, hostname, message_type, sensor_id] = topic.split('/')
    const dataPoint = Array.isArray(data) ? data[data.length - 1] : data
    setMessages((messages) => ({
      ...messages,
      [hostname]: {
        ...messages[hostname],
        [message_type]: {
          ...messages[hostname]?.[message_type],
          [sensor_id || dataPoint.sensor_id || message_type]: dataPoint
        }
      }
    }))
  }, [])

  const client = useMQTTConnect(onMessage)

  return (
    <Container>
      <Tabs>
        <Tab selected={activeTab === 'WECs'} onClick={() => setActiveTab('WECs')}>WECs</Tab>
        <Tab selected={activeTab === 'Pictures'} onClick={() => setActiveTab('Pictures')}>Pictures</Tab>
        <Tab selected={activeTab === 'Data'} onClick={() => setActiveTab('Data')}>Data</Tab>
      </Tabs>
      {activeTab === 'WECs' && wecs?.filter(wec => wec.hidden !== true)?.map(wec => <WECs
        key={wec.id}
        connected={client.connected}
        wec={wec}
        updateWec={updateWec}
        messages={messages[wec.hostname] || []}
      />)}
      {activeTab === 'Pictures' && <Camera wecs={wecs} />}
      {activeTab === 'Data' && <Data />}
    </Container>
  )
}