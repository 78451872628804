import { useState, useMemo, useRef } from 'react'
import styled from 'styled-components'
import { turnOffWec } from '../../server'
import { socket } from '../../socket'
import LiveData from '../Items/LiveData';

const SettingsButton = styled.div`
  cursor: pointer;
  width: fit-content;
  justify-self: center;
  user-select: none;
  padding: 1em;
`

const SettingsContainer = styled.div`
  transition: all 0.05s;
  opacity: 0;
  transform: translate(0, -50px);
  pointer-events: none;
  ${(props) => props.$enabled && `
    opacity: 1;
    transform: translate(0, 0);
    pointer-events: auto;
  `}
`

const Button = styled.button`
  padding: 1em;
  margin: 1em;
  border-radius: 10px;
  background-color: rgb(0 79 255);
  border: none;
  color: white;
  font-family: inherit;
  cursor: pointer
  ${(props) => props.disabled && `
    background-color: transparent;
    cursor: default;
  `}
`

const Title = styled.h1`
  margin: 0
`

export default function Settings({ connected, wec }) {
  const [checkboxDisabled, setCheckboxDisabled] = useState(false)
  const [slider, setSlider] = useState(0)
  const sliderValueRef = useRef(slider)
  const controlInterval = useRef(null);
  const [showSettings, setShowSettings] = useState(false)
  const name = wec.name || wec.hostname || wec.id

  const title = useMemo(() => {
    if (connected === true) {
      return wec?.['online'] ? `${name} is online` : `${name} is offline`
    }
    else if (connected === false)
      return 'No connection'
    else
      return 'Connecting...'
  }, [connected, wec, name])

  async function onTurnOffWec() {
    setCheckboxDisabled(true)
    if (window.confirm(`Are you sure you want to turn off ${name}?`)) {
      try {
        console.log(`Turning off wec ${wec.id}`)
        await turnOffWec(wec.id)
      }
      catch (error) {
        console.error("Error patching wec", error)
      }
    }
    setCheckboxDisabled(false)
  }

  const startSendingMotorControl = () => {
    if (controlInterval.current)
      clearInterval(controlInterval.current)

    // Set up a new interval to send control at 3 Hz
    controlInterval.current = setInterval(() => {
      onSendMotorControl(sliderValueRef.current)
    }, 1000 / 3)
  }

  const stopSendingMotorControl = () => {
    // Stop sending control and reset to 0
    if (controlInterval.current) {
      clearInterval(controlInterval.current)
      controlInterval.current = null
    }
    onSendMotorControl(0)
  }

  function onSendMotorControl(value) {
    socket.emit('motor_control', { value, sid: wec.sid }, ack => {
      console.log(`Motor control ack ${value}: ${ack}`)
      setSlider(ack === 'OK' ? value : null)
    })
  }

  if (!wec)
    return <div />

  return (
    <div style={{ width: '100%' }}>
      <div>
        <Title>{title}</Title>
        <LiveData wec={wec} />
      </div>
      {wec['online'] && <div>
        <SettingsButton onClick={() => setShowSettings(value => !value)}>Settings {showSettings ? "▼" : "◀"}</SettingsButton>
        <SettingsContainer $enabled={showSettings}>
          <Button
            disabled={checkboxDisabled}
            onClick={onTurnOffWec}
          >Turn off</Button>
          {(wec.id === "10000000aba13dfa" || wec.id === "ERROR000000000" || wec.id === "7a6073dd77162609") &&
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', gap: '8px', flexWrap: 'wrap', margin: '1em' }}>
              Motor control
              <div style={{display: "flex", justifyContent: "center"}}>
                <input type="range" min="-3.3" max="3.3" step="0.1" value={slider} style={{width: "100%", maxWidth: "20rem"}}
                  onInput={e => {
                    setSlider(e.target.value)
                    sliderValueRef.current = e.target.value
                  }}
                  onMouseDown={e => startSendingMotorControl()}
                  onMouseUp={e => stopSendingMotorControl()}
                  onTouchStart={e => startSendingMotorControl()}
                  onTouchEnd={e => stopSendingMotorControl()}
                />
                <div style={{display: 'inline-block', minWidth: '2.5rem', textAlign: 'end'}}>{slider}V</div>
              </div>
            </div>
          }
        </SettingsContainer>
      </div>}
    </div>
  )
}
