import styled from 'styled-components'

export const Button = styled.button`
  display: inline-block;
  background-color: rgb(0 79 255);
  border: none;
  color: white;
  width: 8em;
  height: 3em;
  // margin: auto 1em;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  font-family: inherit;
  cursor: pointer;
  ${(props) => props.disabled && `
    color: rgb(169 169 169 / 50%);
    cursor: default;
  `}
  &:active{
    background-color: rgb(0 79 255 / 50%);
  }
`