import 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';

export default function LineChart({ type, data, label, xlabel, ylabel }) {
  return (
    <Chart
      type={type}
      data={{
        datasets: [{
          label,
          data
        }]
      }}
      options={{
        plugins: {
          zoom: {
            enabled: true,
            mode: 'x',
          },
          pan: {
            enabled: true,
            mode: 'x',
          },
          legend: {
            labels: {
              color: '#FFFFFF'
            }
          }
        },
        scales: {
          x: {
            type: 'time',
            time: {                                                                                                                                                                  
              tooltipFormat: 'yyyy-MM-dd HH:mm:ss.SSS',
              displayFormats: {
                millisecond: 'HH:mm:ss.SSS',
                second: 'HH:mm:ss',
                minute: 'HH:mm',
                hour: 'HH'
              }
            },
            ticks: {
              color: '#FFFFFF'
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.1)'
            },
            title: {
              display: true,
              text: xlabel,
              color: '#FFFFFF'
            }
          },
          y: {
            ticks: {
              color: '#FFFFFF'
            },
            grid: {
              color: 'rgba(255, 255, 255, 0.1)'
            },
            title: {
              display: true,
              text: ylabel,
              color: '#FFFFFF'
            }
          }
        },
        tooltips: {
          titleFontColor: '#FFFFFF',
          bodyFontColor: '#FFFFFF'
        }
      }}
    />
  )
}